<template>
  <b-card id="import-accounts-payable-card">
    <!-- ANCHOR top -->
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <template #label>
            <div class="text-heading-4">
              {{ $t('accountsPayable.fileType') }}
            </div>
          </template>
          <v-select
            v-model="fileType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="['Báo cáo bán', 'Sao kê']"
            :clearable="false"
            :placeholder="$t('accountsPayable.selectFileTypePlaceholder')"
          >
            <template #option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('noOptions') }}
            </template>
            <span class="text-muted ml-50">{{ $t('agency.agencies') }}</span>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form novalidate>
          <b-form-group>
            <template #label>
              <div class="text-heading-4">
                {{ $t('accountsPayable.chooseFileToUpload') }}
              </div>
            </template>
            <div
              class="dropbox cursor-pointer d-flex-center"
            >
              <input
                type="file"
                accept=".csv, .xlsx"
                class="input-file"
                @change="importSaleReport($event.target.files)"
              >
              <div class="d-flex-center">

                <img
                  src="@icons/uploadFile.svg"
                  alt="Upload file"
                  width="24px"
                  class="mr-50"
                >
                <span class="text-body-2">{{ $t('accountsPayable.chooseFileToUpload') }}</span>
              </div>
            </div>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>

    <!-- ANCHOR download sample file -->
    <div class="d-flex-center justify-content-start">
      <div class="text-heading-4 mr-50">
        {{ $t('accountsPayable.downloadSampleFile') }}
      </div>
      <b-button
        variant="flat-info"
        class="py-25"
        @click="confirmExport()"
      >
        <div class="d-flex-center">
          <img
            src="@icons/downloadFile.svg"
            alt="Download file"
            width="24px"
            class="mr-50"
          >
          <span class="text-body-2">{{ $t('accountsPayable.sampleFile') }}</span>
        </div>
      </b-button>
    </div>

    <!-- ANCHOR table -->
    <b-table
      ref="refEmployeeListTable"
      style="max-height: 100%"
      :sticky-header="true"
      class="position-relative mt-1"
      :items="dataImport"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t('noMatchingResult')"
      no-border-collapse
    >

      <template
        v-for="column in tableColumns"
        v-slot:[`head(${column})`]="data"
      >
        <span
          :key="column"
          class="text-dark text-nowrap"
        >
          {{ data.label }}
          <!-- {{ $t(`accountsPayable.${data.label}`) }} -->
        </span>
      </template>
      <template
        v-for="column in tableColumns"
        v-slot:[`cell(${column})`]="data"
      >
        <div
          :key="column"
          class="text-nowrap"
          style="min-width: 100px;"
        >
          {{ data.item[column] }}
        </div>
      </template>

    </b-table>

    <!-- ANCHOR button -->
    <div class="d-flex-center justify-content-end">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        class=" width-150 mr-1"
        variant="outline-danger"
        pill
      >
        <!-- :disabled="emptyFilter" -->
        <!-- @click="$emit('reset'), resetFilter()" -->
        <!-- <feather-icon
            icon="RefreshCwIcon"
            class="mr-50"
          /> -->
        <span
          class="align-middle"
          style=""
        >{{ $t('cancel') }}
        </span>
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient  width-150"
        pill
        @click="$emit('fetch-data')"
      >
        <!-- :disabled="emptyFilter" -->
        <!-- <feather-icon
            icon="SearchIcon"
            class="mr-50"
          /> -->
        <span class="align-middle">{{ $t('save') }}</span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BTable, BFormGroup, BForm,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'

import store from '@/store'

import { useInputExcelToArray } from '@core/comp-functions/forms/form-utils'

import accountsPayableStoreModule from '@accountsPayable/accountsPayableStoreModule'
import useAccountsPayableHandle from '@accountsPayable/useAccountsPayableHandle'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    vSelect,
  },
  setup() {
    const ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME = 'app-account-payable'

    // Register module
    if (!store.hasModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME, accountsPayableStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME)
    })

    const fileType = ref()
    const tableColumns = ref(['date', 'file', 'agency'])
    const dataImport = ref([])
    const items = ref([
      {
        date: '05:46 19/03/2023',
        file: 'Công nợ 1',
        agency: 'Trương Thị Quyên',
      },
      {
        date: '05:46 19/03/2023',
        file: 'Công nợ 2',
        agency: 'Trương Thị Quyên',
      },
      {
        date: '05:46 19/03/2023',
        file: 'Công nợ 3',
        agency: 'Trương Thị Quyên',
      },
    ])

    const {
      exportSaleReportTemplate,
    } = useAccountsPayableHandle()

    async function importSaleReport(data) {
      // TODO: use useExcelToArray() instead of useInputExcelToArray()
      dataImport.value = await useInputExcelToArray(data[0])
      console.log(dataImport.value)
    }

    function confirmExport() {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.exportSaleReportTemplate()
          }
        })
    }

    return {
      fileType,
      tableColumns,
      items,
      dataImport,
      confirmExport,
      exportSaleReportTemplate,
      importSaleReport,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.dropbox {
  border: 1px dashed #ccc; /* the dash box */
  background: transparent;
  color: dimgray;
  padding: 0.5rem;
  position: relative;
  border-radius: 8px;

  &:hover {
  background: #f6f6f6; /* when mouse over to the drop zone, change color */
  // opacity: 50%;
  }
}

.input-file {
  opacity: 0%;
  width: 100%;
  // height: 200px;
  position: absolute;
  cursor: pointer;
}
</style>
